import React, {useState} from 'react'
import { isMobile } from 'react-device-detect';
import BigButton from '../utils/BigButton/BigButton'
const {REACT_APP_ENDPOINT}=process.env

export const CvForm = ({sent, handleSubmit, handleChange, errors, candidateInfo, className, classDark, formTexts, sendButton, placeholders, cvResponse, uploadButton, errorNetworkMessage, onUploadFileError}) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [newScope, setNewScope] = useState('');
  const [notification, setNotification] = useState(null)
  const [loading, setLoading] = useState(false)

//    const uploadFile = async () => {
//     const formData = new FormData();
//     formData.append("file", file);
//     formData.append("fileName", fileName);

//     fetch(`${REACT_APP_ENDPOINT}public-api/upload-file`, {
//       method: 'POST',
//       body: formData,
//     }).then((response) => {
//       console.log(response.json())
//       response.json().then((body) => {
//         setNotification( body.success ? 'El archivo se subió correctamente': body.message);
//         setNewScope(body.file)
//         candidateInfo.cv = body.RN;
//  //       !body.success ?? onUploadFileError()
//       });
//     }).catch(errors => console.log(errors));
//   };

  const uploadFile = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    try {
      const response = await fetch(`${REACT_APP_ENDPOINT}public-api/upload-file`, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        onUploadFileError()
        throw new Error(`Error en la carga del archivo: ${response.statusText}`);
      }
  
      const body = await response.json();
      setNotification(body.success ? 'El archivo se subió correctamente' : body.message);
      setLoading(false)
      setNewScope(body.file);
      candidateInfo.cv = body.RN;
    } catch (error) {
      setNotification('No se ha podido subir el archivo');
      setLoading(false)
      console.error('Error:', error);
    }
  };
  
  const saveFile = e => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    handleChange(e, 'cv', 'file', true)
  };

  return (
    <div className='cv-form'>
    <form className= {sent===true ? 'display-none':'inscription-form'} onSubmit={handleSubmit}>
    <div className='input-box'>
    <label className='label-white'>{formTexts.firstName}</label>
      <input name="userName" className={className} value={candidateInfo.userName} type="onlyletters" onChange={e => handleChange(e, 'userName', 'onlyletters', true, 75, 3)} placeholder={placeholders.firstName} required={true}/>
      {errors['userName'] && <p className='error-white '>{errors['userName']}</p>}
    </div>
    <div className='input-box'>
    <label className='label-white'>{formTexts.lastName}</label>
      <input name="userLastName" value={candidateInfo.userLastName} className={className} type="onlyletters" onChange={e => handleChange(e, 'userLastName', 'onlyletters', true, 75, 3)} placeholder={placeholders.lastName} required={true}/> 
      {errors['userLastName'] && <p className='error-white'>{errors['userLastName']}</p>}
    </div>
    <div className='input-box'>
    <label className='label-white'>Email</label>
      <input name="email" className={className} value={candidateInfo.email} type="email" onChange={e => handleChange(e, 'email', 'email', true, 75, 8)} placeholder={placeholders.email} required={true}/>
      {errors['email'] && <p className='error-white'>{errors['email']}</p>}
    </div>
    <div className='input-box'>
    <label className='label-white'>{formTexts.phone}</label>
      <input name="phone" className={className} value={candidateInfo.phone} type="number" onChange={e => handleChange(e, 'phone', 'number', true, 15, 7)} placeholder={placeholders.phone} required={true}/>
      {errors['phone'] && <p className='error-white'>{errors['phone']}</p>}
    </div>
    <div className='input-box'>
    <label className='label-white'>DNI</label>
      <input name="dni" className={className} value={candidateInfo.dni} type="text" onChange={e => handleChange(e, 'dni', 'number', true, 15, 7)} placeholder={placeholders.dni} required={true}/>
      {errors['dni'] && <p className='error-white'>{errors['dni']}</p>}
    </div>
    <div className='input-box'>
    
    <label className='label-white'>{formTexts.attachCv}</label>
      <input name="cv" className="rent-file-input" type="file" onChange={saveFile} required={true} />
      {errors['cv'] && <p className='error-white'>{errors['cv']}</p>}
      <button className={isMobile?'upload-button-mobile': "upload-button"}type="button" onClick={uploadFile}>{uploadButton}</button> 
      <p className='rent-notification'>
        {loading ? "Loading" : newScope ? `Nombre: ${newScope} ` : null}{notification}</p>
    </div>
    
    <br/>
    <div className='input-box-content'>
    <label className='label-white'>{formTexts.message}</label>  
      <textarea name="content" className={classDark} value={candidateInfo.content} type="text" onChange={e => handleChange(e, 'content', 'text', true, 250, 3)} placeholder={placeholders.message} />
      {errors['content'] && <p className='error-white'>{errors['content']}</p>}
    </div>
    <div className='cv-send'>
    <BigButton type="submit" name={sendButton} className="contact-dark-button" disabled={loading}/>
    </div>
    {cvResponse.error ? <p className='network-error'>{errorNetworkMessage}</p> : null}
    </form>
    </div>
  )
}

